import { Editor } from '@tinymce/tinymce-react';
import Modal from 'antd/lib/modal/Modal';
import React, { useRef, useState } from 'react';
import MediaPicker from './media-picker';
import { uploadFile } from '../../lib/axios';
import { notification } from 'antd';

const editor = React.memo((prop: any) => {
	const { value, onChange, initialValue, folder } = prop;
	const urlCallback = useRef<any>(undefined);
	const [showMediaSelector, setShowMediaSelector] = useState(false);

	const imagesUploadHandler = (
		blobInfo: any,
		progress: (percentage: number) => void,
	): Promise<string> => {
		return new Promise((resolve, reject) => {
			const file = blobInfo.blob();
			uploadFile({
				file,
				folder,
				name: blobInfo.filename(),
				watermark: false,
			})
				.then((re) => {
					if (re.data.data) {
						resolve(re.data.data.url);
						notification['info']({
							message: 'Uploading',
							description: 'File uploaded successfully!',
							duration: 2,
						});
					} else {
						reject('Upload failed');
					}
				})
				.catch((err) => {
					reject('Upload failed');
					notification['error']({
						message: 'Error',
						description: 'File upload failed!',
						duration: 2,
					});
				});
		});
	};

	return (
		<>
			<Modal
				zIndex={4000}
				title="Image Picker"
				open={showMediaSelector}
				footer={null}
				onCancel={() => {
					setShowMediaSelector(false);
				}}
				width={850}
			>
				{showMediaSelector ? (
					<MediaPicker
						callBack={(url) => {
							if (urlCallback.current && url) {
								urlCallback.current(url);
							}
							setShowMediaSelector(false);
						}}
					/>
				) : (
					<div></div>
				)}
			</Modal>

			<Editor
				apiKey={'l55t5a5he4onuyxrd6au0sk1qhqtvk9q033vxd8ol98tf694'}
				initialValue={initialValue}
				value={value}
				init={{
					width: '100%',
					height: 400,
					block_unsupported_drop: false,
					plugins: [
						'advlist',
						'accordion',
						'autosave',
						'media',
						'table',
						'image',
						'anchor',
						'autolink',
						'charmap',
						'directionality',
						'emoticons',
						'insertdatetime',
						'link',
						'lists',
						'nonbreaking',
						'pagebreak',
						'preview',
						'visualblocks',
						'visualchars',
						'fullscreen',
						'wordcount',
						'help',
						'code',
					],
					autosave_interval: '30s',
					autosave_ask_before_unload: true,
					autosave_prefix: 'tinymce-autosave-{path}{query}-{id}-',
					autosave_retention: '4320m', // 3 days

					// save_onsavecallback: function() {
					// 	// Gets the content from the editor
					// 	const content = this.getContent();
					// 	// this.console.log('Saved ->', content);
					// 	localStorage.setItem('Latest Saved content', content);
					// },
					toolbar: [
						'undo redo | h1 h2 h3 h4 h5 h6 | formatselect | fontfamily fontsize |' +
							'bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | copy paste selectall remove print | emoticons charmap |' +
							'bullist numlist indent outdent lineheight |' +
							'forecolor backcolor | removeformat | subscript superscript blockquote | hr | ' +
							'link unlink anchor pagebreak | image | code | media | fullscreen preview |' +
							'help restore',
					],

					// menubar: false,
					font_family_formats:
						'Avenir Black=Avenir Black; Avenir Heavy=Avenir Heavy; Avenir Medium=Avenir Medium;',

					// menu: {
					// 	favs: {
					// 		title: 'My Favorites',
					// 		items: 'code visualaid | searchreplace | emoticons',
					// 	},
					// 	insert: {
					// 		title: 'Insert',
					// 		items:
					// 			'image link media codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor toc | insertdatetime',
					// 	},
					// },
					file_picker_types: 'image',
					menubar: 'favs file edit view insert format tools table help',
					file_picker_callback: function (callback) {
						urlCallback.current = callback;
						setShowMediaSelector(true);
					},
					images_upload_handler: imagesUploadHandler,
				}}
				onEditorChange={(v) => {
					onChange(v);
				}}
			/>
		</>
	);
});

export default editor;
